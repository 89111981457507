var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "table-container" },
    [
      _c("div", { staticClass: "system-info" }, [
        _c("div", { staticClass: "system-center" }, [
          _c("div", [_vm._v("积分体系信息")]),
          _c("div", [
            _vm._v("运营主体：" + _vm._s(_vm.sysIntegralInfo.regionName)),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "subject-info" }, [
        _c("div", { staticClass: "subject-left" }, [
          _c("div", { staticClass: "username" }, [
            _c("div", [_vm._v("用户可用积分")]),
            _c("div", [_vm._v(_vm._s(_vm.sysIntegralInfo.availableIntegral))]),
          ]),
          _c("div", { staticClass: "username" }, [
            _c("div", [_vm._v("累计发放积分")]),
            _c("div", [_vm._v(_vm._s(_vm.sysIntegralInfo.totalIntegral))]),
          ]),
          _c("div", { staticClass: "username" }, [
            _c("div", [_vm._v("累计消耗积分")]),
            _c("div", [_vm._v(_vm._s(_vm.sysIntegralInfo.usedIntegral))]),
          ]),
          _c("div", { staticClass: "username" }, [
            _c("div", [_vm._v("过期积分")]),
            _c("div", [_vm._v(_vm._s(_vm.sysIntegralInfo.overIntegral))]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "subject-right" },
          [
            _c("v-button", {
              attrs: { text: "积分发放记录", permission: "grant" },
              on: {
                click: function ($event) {
                  return _vm.grantRecord()
                },
              },
            }),
            _c("v-button", {
              attrs: { text: "积分消耗记录", permission: "expend" },
              on: {
                click: function ($event) {
                  return _vm.expendRecord()
                },
              },
            }),
          ],
          1
        ),
      ]),
      _vm._m(0),
      _c("div", { staticClass: "basic-info" }, [
        _c("div", { staticClass: "basic-left" }, [
          _c(
            "div",
            { staticClass: "panel-wrap" },
            [
              _c(
                "form-panel",
                {
                  ref: "form",
                  attrs: { form: _vm.form, hasHeader: _vm.hasHeader },
                },
                [
                  _vm.basicEditFlag
                    ? _c("div", [
                        _c(
                          "div",
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "积分名称",
                                  prop: "integralName",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请输入积分名称",
                                      trigger: ["blur", "change"],
                                    },
                                  ],
                                },
                              },
                              [
                                _c("v-input", {
                                  attrs: {
                                    placeholder: "如：金币、绿叶,最多五个字",
                                    maxlength: 5,
                                    width: _vm.width,
                                  },
                                  model: {
                                    value: _vm.form.integralName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "integralName", $$v)
                                    },
                                    expression: "form.integralName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticStyle: { display: "flex" } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "积分参考",
                                  prop: "intNum",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请输入积分参考",
                                      trigger: ["blur", "change"],
                                    },
                                  ],
                                },
                              },
                              [
                                _c("v-input-number", {
                                  attrs: {
                                    max: 99999,
                                    min: 0,
                                    width: _vm.width1,
                                  },
                                  model: {
                                    value: _vm.form.intNum,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "intNum", $$v)
                                    },
                                    expression: "form.intNum",
                                  },
                                }),
                                _c("span", { staticClass: "basic-row-text" }, [
                                  _vm._v("积分="),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "yuan-container" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "unit",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请输入积分参考",
                                          trigger: ["blur", "change"],
                                        },
                                      ],
                                    },
                                  },
                                  [
                                    _c("v-input-number", {
                                      attrs: {
                                        max: 99999,
                                        precision: 2,
                                        min: 0,
                                        width: _vm.width1,
                                      },
                                      model: {
                                        value: _vm.form.unit,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "unit", $$v)
                                        },
                                        expression: "form.unit",
                                      },
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "basic-row-text" },
                                      [_vm._v("元")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ])
                    : _c(
                        "div",
                        [
                          _c("el-form-item", { attrs: { label: "积分名称" } }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.sysIntegralInfo.integralName) +
                                "\n            "
                            ),
                          ]),
                          _c("el-form-item", { attrs: { label: "分值参考" } }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.sysIntegralInfo.intNum) +
                                "积分=" +
                                _vm._s(_vm.sysIntegralInfo.unit) +
                                "元\n            "
                            ),
                          ]),
                        ],
                        1
                      ),
                  _vm.basicEditFlag
                    ? _c(
                        "div",
                        { attrs: { slot: "footerSlot" }, slot: "footerSlot" },
                        [
                          _c("v-button", {
                            staticClass: "botBtn",
                            attrs: { text: "返回" },
                            on: {
                              click: function ($event) {
                                _vm.basicEditFlag = false
                              },
                            },
                          }),
                          _c("v-button", {
                            attrs: { text: "保存", type: "success" },
                            on: { click: _vm.submitGuide },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "basic-right" },
          [
            _c("v-button", {
              attrs: { text: "编辑", permission: "update" },
              on: {
                click: function ($event) {
                  _vm.basicEditFlag = true
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c("tabs-panel", {
        ref: "tabs",
        staticClass: "tabs-panel",
        attrs: {
          tabs: _vm.tabs,
          keepAlive: false,
          activeLabel: _vm.activeLabel,
        },
        on: {
          "update:activeLabel": function ($event) {
            _vm.activeLabel = $event
          },
          "update:active-label": function ($event) {
            _vm.activeLabel = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "line-style" }, [
      _c("span", [_vm._v("积分基本信息")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }