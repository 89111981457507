var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "custom-wrap" },
    [
      _c("div", { staticClass: "custom-container" }, [
        _c(
          "div",
          { staticClass: "custom-add" },
          [_c("v-button", { attrs: { text: "新增" }, on: { click: _vm.add } })],
          1
        ),
      ]),
      _c(
        "list",
        {
          ref: "list",
          attrs: {
            searchUrl: _vm.searchUrl,
            searchParams: _vm.searchParams,
            headers: _vm.headers,
            isMultiSelect: true,
          },
          on: {
            "update:searchParams": function ($event) {
              _vm.searchParams = $event
            },
            "update:search-params": function ($event) {
              _vm.searchParams = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "operateSlot",
              fn: function (scope) {
                return [
                  !scope.row.edit
                    ? _c(
                        "v-button",
                        {
                          attrs: {
                            type: "text",
                            disabled: scope.row.rulesOn === 1 ? false : true,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.edit(scope.row)
                            },
                          },
                        },
                        [_vm._v("编辑")]
                      )
                    : _vm._e(),
                ]
              },
            },
            {
              key: "batchSlot",
              fn: function (scope) {
                return [
                  _c("v-button", {
                    attrs: { text: "批量生成二维码" },
                    on: {
                      click: function ($event) {
                        return _vm.batchQRCodeHandle(scope.selectedData)
                      },
                    },
                  }),
                ]
              },
            },
          ]),
        },
        [
          _c("qrcode-collection", {
            attrs: {
              "qrcode-list": _vm.qrcodeList,
              visible: _vm.qrcodeCollectionShow,
            },
            on: {
              "update:visible": function ($event) {
                _vm.qrcodeCollectionShow = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "qrcodeSlot",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _c("div", [_vm._v(_vm._s(scope.row.taskName))]),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }