<template>
  <div class="table-container">
    <div class="system-info">
      <div class="system-center">
        <div>积分体系信息</div>
        <div>运营主体：{{ sysIntegralInfo.regionName }}</div>
      </div>
    </div>
    <div class="subject-info">
      <div class="subject-left">
        <div class="username">
          <div>用户可用积分</div>
          <div>{{ sysIntegralInfo.availableIntegral }}</div>
        </div>
        <div class="username">
          <div>累计发放积分</div>
          <div>{{ sysIntegralInfo.totalIntegral }}</div>
        </div>
        <div class="username">
          <div>累计消耗积分</div>
          <div>{{ sysIntegralInfo.usedIntegral }}</div>
        </div>
        <div class="username">
          <div>过期积分</div>
          <div>{{ sysIntegralInfo.overIntegral }}</div>
        </div>
      </div>
      <div class="subject-right">
        <v-button
          text="积分发放记录"
          permission="grant"
          @click="grantRecord()"
        ></v-button>
        <v-button
          text="积分消耗记录"
          permission="expend"
          @click="expendRecord()"
        ></v-button>
      </div>
    </div>
    <div class="line-style"><span>积分基本信息</span></div>
    <div class="basic-info">
      <div class="basic-left">
        <div class="panel-wrap">
          <form-panel ref="form" :form="form" :hasHeader="hasHeader">
            <div v-if="basicEditFlag">
              <div>
                <el-form-item
                  label="积分名称"
                  prop="integralName"
                  :rules="[
                    {
                      required: true,
                      message: '请输入积分名称',
                      trigger: ['blur', 'change'],
                    },
                  ]"
                >
                  <v-input
                    v-model="form.integralName"
                    placeholder="如：金币、绿叶,最多五个字"
                    :maxlength="5"
                    :width="width"
                  />
                </el-form-item>
              </div>

              <div style="display: flex">
                <el-form-item
                  label="积分参考"
                  prop="intNum"
                  :rules="[
                    {
                      required: true,
                      message: '请输入积分参考',
                      trigger: ['blur', 'change'],
                    },
                  ]"
                >
                  <v-input-number
                    v-model="form.intNum"
                    :max="99999"
                    :min="0"
                    :width="width1"
                  />
                  <span class="basic-row-text">积分=</span>
                </el-form-item>
                <div class="yuan-container">
                  <el-form-item
                    prop="unit"
                    :rules="[
                      {
                        required: true,
                        message: '请输入积分参考',
                        trigger: ['blur', 'change'],
                      },
                    ]"
                  >
                    <v-input-number
                      v-model="form.unit"
                      :max="99999"
                      :precision="2"
                      :min="0"
                      :width="width1"
                    />
                    <span class="basic-row-text">元</span>
                  </el-form-item>
                </div>
              </div>
            </div>
            <div v-else>
              <el-form-item label="积分名称">
                {{ sysIntegralInfo.integralName }}
              </el-form-item>
              <el-form-item label="分值参考">
                {{ sysIntegralInfo.intNum }}积分={{ sysIntegralInfo.unit }}元
              </el-form-item>
            </div>
            <div slot="footerSlot" v-if="basicEditFlag">
              <v-button
                class="botBtn"
                text="返回"
                @click="basicEditFlag = false"
              ></v-button>
              <v-button
                text="保存"
                type="success"
                @click="submitGuide"
              ></v-button>
            </div>
          </form-panel>
        </div>
      </div>
      <div class="basic-right">
        <v-button
          text="编辑"
          @click="basicEditFlag = true"
          permission="update"
        ></v-button>
      </div>
    </div>
    <tabs-panel
      ref="tabs"
      class="tabs-panel"
      :tabs="tabs"
      :keepAlive="false"
      :activeLabel.sync="activeLabel"
    />
  </div>
</template>

<script>
import { TabsPanel } from "@/components/bussiness";
import systemTask from './systemTask'
import customTask from './customTask'
import {
  getListByPage,
  updateSysIntegralConfig,
  updateSysIntegralInfoURL,
} from "./api";
export default {
  name: "ustomIntegraRules",
  components: {
    TabsPanel,
  },
  data() {
    return {
      activeLabel: '系统任务',
      tabs: [
        {
          label: '系统任务',
          component: systemTask
        },
        {
          label: '自定义任务',
          component: customTask
        }
      ],
      hasHeader: false,
      basicEditFlag: false,
      form: {
        integralName: "",
        intNum: undefined,
        unit: undefined,
      },
      width: 220,
      width1: 50,
      mapArr: [],
      sysIntegralInfo: {},
    };
  },
  created() {},
  mounted() {
    console.log(this.$store.state.userInfo, "iiiiii");
    this.getSysIntegralInfo();
  },
  methods: {
    getSysIntegralInfo() {
      // 获取用户基础信息
      let params = {
        // this.$store.state.userInfo.regionId
        // regionId: "eb1e1ad8-85af-42d0-ba3c-21229be19009",
        regionId: this.$store.state.userInfo.orgId
      };
      this.$axios.get(getListByPage, { params: params }).then((res) => {
        if (res.status === 100 && res.data) {
          this.sysIntegralInfo = res.data;
          this.form.integralName = res.data.integralName;
          this.form.intNum = res.data.intNum || undefined;
          this.form.unit = res.data.unit || undefined;
          // const list = res.data.configVList;
          // this.handleWashingData(res.data.configVList);
          // list.forEach((item) => {
          //   item.edit = false;
          // });
          // this.tableData = list;
        }
      });
    },

    async handleSwitchChange(e, row) {
      let text;
      if (e === 1) {
        row.rulesOn = 0;
        text = "开启";
      } else {
        row.rulesOn = 1;
        text = "关闭";
      }
      const status = await this.$confirm(`确认${text}该积分任务?`, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        distinguishCancelAndClose: true,
        closeOnClickModal: false,
        type: "warning",
      });
      this.handleConfirmCallback(status, row);
    },
    // 弹窗按钮点击回调
    handleConfirmCallback(status, row) {
      const ruleStatus = {
        true: 1,
        false: 0,
      };
      if (status && row.rulesOn === 1) {
        row.rulesOn = ruleStatus[!status];
        // 开启回调
        this.handleChangeRules(row);
      } else if (status && row.rulesOn === 0) {
        row.rulesOn = ruleStatus[status];
        // 关闭回调
        this.handleChangeRules(row);
      }
    },

    edit(row) {
      // row.edit = true;
      this.$router.push({
        path: "systemIntegraRulesForm",
        query: {
          // userInfo: JSON.stringify(this.userInfo),
          id: row.id,
        },
      });
    },
    // 规则开启关闭
    async handleChangeRules(row) {
      const { id, rulesOn } = row;
      const data = { id, rulesOn };
      await this.$axios.post(updateSysIntegralConfig, data);
      this.getSysIntegralInfo();
    },

    handleReplaceNumber(val) {
      return val.toString().replace(/\.|\-/g, "").slice(0, 8) - 0;
    },

    submitGuide() {
      let isContinue = this.$refs.form.validate();
      if (!isContinue) {
        return;
      }
      let data = this.form;
      let params = {
        intNum: data.intNum,
        integralName: data.integralName,
        regionId: this.$store.state.userInfo.orgId,
        // regionId: "eb1e1ad8-85af-42d0-ba3c-21229be19009",
        unit: data.unit,
        id: this.sysIntegralInfo.id,
      };
      this.$axios.post(updateSysIntegralInfoURL, params).then((res) => {
        if (res.status === 100) {
          this.$message({
            type: "success",
            message: "保存成功",
            center: true,
          });
          this.getSysIntegralInfo();
          this.basicEditFlag = false;
          // this.$router.go(-1)
        }
      });
    },

    grantRecord() {
      this.$router.push({
        path: "integraSendRecords",
        query: {
          regionId: this.$store.state.userInfo.orgId,
        },
      });
    },
    expendRecord() {
      this.$router.push({
        path: "integraExpendRecords",
        query: {
          regionId: this.$store.state.userInfo.regionId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  overflow: scroll;
  .system-info {
    border: 1px solid rgba(242, 242, 242, 1);
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 30px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(251, 251, 251, 1);
    color: #666;
    font-size: 14px;
  }
  .subject-info {
    width: 100%;
    padding: 10px 20px;
    // margin-left: 20px;
    box-sizing: border-box;
    // background-color: rgba(251, 251, 251, 1);
    display: flex;
    justify-content: space-between;
    // border: 1px solid rgba(242, 242, 242, 1);
    border-radius: 4px;
    margin-bottom: 20px;
    .subject-left {
      display: flex;
      align-items: center;
      height: 100px;
      font-size: 20px;
      font-weight: bold;
      color: #666;
      justify-content: space-around;
      flex: 1;
      .username {
        flex: 1;
      }
    }
    .subject-right {
      > div {
        display: block;
        margin-top: 12px;
      }
    }
  }
  .basic-info {
    display: flex;
    justify-content: space-between;
    padding: 30px 20px 10px;
    .basic-left {
      flex: 1;
      justify-content: center;
      display: flex;
      .panel-wrap {
        width: 50%;
        .basic-row-text {
          padding: 0 10px;
          color: #606266;
        }
        .yuan-container {
          ::v-deep .el-form-item__content {
            margin-left: 0 !important;
          }
        }
        .botBtn {
          margin-right: 10px;
        }
      }
    }
  }
  .line-style {
    background-color: rgba(251, 251, 251, 1);
    padding: 10px 30px;
    text-align: left;
    position: relative;
    &::before {
      content: "";
      display: inline-block;
      width: 4px;
      height: 20px;
      position: absolute;
      left: 20px;
      top: 50%;
      margin-top: -10px;
      background: #409eff;
    }
  }
  .btn-box {
    ::v-deep .el-button {
      width: 100%;
      margin-top: 5px;
      display: block;
    }
  }
  ::v-deep .el-tooltip {
    padding-right: 10px;
  }
  ::v-deep .el-icon-question {
    position: absolute;
    top: 15px;
  }
  ::v-deep input::-webkit-outer-spin-button,
  ::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  ::v-deep input[type="number"] {
    -moz-appearance: textfield;
  }
}
</style>
