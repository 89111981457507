var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "table-container" },
    [
      _c("table-panel", {
        attrs: {
          headers: _vm.headers,
          tableData: _vm.tableData,
          "span-method": _vm.objectSpanMethod,
          operateColumnWidth: "120",
        },
        scopedSlots: _vm._u([
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                !scope.row.edit
                  ? _c(
                      "v-button",
                      {
                        attrs: {
                          type: "text",
                          disabled: scope.row.rulesOn === 1 ? false : true,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.edit(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }