<template>
  <div class="table-container">
    <table-panel
      :headers="headers"
      :tableData="tableData"
      :span-method="objectSpanMethod"
      operateColumnWidth="120"
    >
      <template #operateSlot="scope">
        <v-button
          v-if="!scope.row.edit"
          type="text"
          :disabled="scope.row.rulesOn === 1 ? false : true"
          @click="edit(scope.row)"
          >编辑</v-button
        >
      </template>
    </table-panel>
  </div>
</template>

<script>
import { TablePanel } from "@/components/bussiness";
import {
  getListByPage,
  updateSysIntegralConfig,
  updateSysIntegralInfoURL,
} from "./api";
import {
  createSwitchVNode,
  createHTMLVNode,
  createInputVNode,
  createTooltipVNode,
  createSelectVNode,
  createInputSpanVNode,
  createRulesVNode,
  createNumberVNode,
} from "common/vdom";
import {
  integraTimeOptions,
  ruleTypeOptions,
  ruleTypeMap,
  integraTimeMap,
  limitTypeOptions,
  limitTypeMap,
  consumMap,
  consumTypeOptions,
} from "./map";
export default {
  name: "CustomIntegraRules",
  components: {
    TablePanel,
  },
  data() {
    return {
      hasHeader: false,
      headers: [
        {
          prop: "classify",
          label: "分类",
          width: 100,
        },
        {
          prop: "taskName",
          label: "任务",
          width: 150,
          formatter: (row, prop) => {
            if (row.remarks) {
              const opts = {
                effect: "dark",
                content: row.remarks,
                placement: "right-start",
                hasIcon: true,
                hasMessage: true,
                iconClassName: "el-icon-question tooltip",
                message: row[prop],
                visibleArrow: true,
                offset: 100,
              };
              return createTooltipVNode(this, row, prop, opts);
            } else {
              return createHTMLVNode(this, row[prop]);
            }
          },
        },
        {
          prop: "rulesOn",
          label: "规则开启",
          width: 80,
          formatter: (row, prop) => {
            const opts = {
              // change 回调
              cb: ($event) => {
                this.handleSwitchChange($event, row);
              },
              activeValue: 1,
              inactiveValue: 0,
              value: row.rulesOn,
              activeColor: "#13ce66",
              inactiveColor: "#ff4949",
            };
            return createSwitchVNode(this, row, prop, opts);
          },
        },
        {
          prop: "rulesType",
          label: "规则",
          width: 200,
          formatter: (row, prop) => {
            if (row.edit) {
              // rulesType 为 3 则表示是订单消费行
              if (row.rulesType === 3) {
                const opts = {
                  options: consumTypeOptions,
                  spanInnerTextOne: "每消费",
                  inputNumberWidth: 60,
                  selectWidth: 70,
                  methods: {
                    input: (newValue, el) => {
                      const newJson = JSON.parse(row.rulesJson);
                      newJson.value = this.handleReplaceNumber(newValue);
                      el.changeModel(newJson.value);
                      row.rulesJson = JSON.stringify(newJson);
                    },
                  },
                  attrs: {
                    type: "number",
                  },
                };
                return createRulesVNode(this, row, "rulesJson", opts);
              }
              return createSelectVNode(this, row, prop, {
                options: ruleTypeOptions,
              });
            } else if (row.rulesType === 3) {
              if (row.taskName === "预存(二)") {
                // console.log(JSON.parse(row.rulesJson),'JSON.parse(row.rulesJson)');
                let innerHTML = ""
                JSON.parse(row.rulesJson).forEach((item,index)=>{
                  innerHTML += `每次，${item.money}元<br>` //每次，1500元
                })
                // console.log(innerHTML,'innerHTML');
                return createHTMLVNode(
                  this,
                  innerHTML
                );
              }
              const money = JSON.parse(row.rulesJson).value;
              const moneyType = consumMap[JSON.parse(row.rulesJson).type];
              return `每消费 ${money} ${moneyType}`;
            } else {
              return createHTMLVNode(this, ruleTypeMap[row[prop]]);
            }
          },
        },
        {
          prop: "intNum",
          label: "奖励积分",
          width: 180,
          formatter: (row, prop) => {
            if (row.edit) {
              const opts = {
                methods: {
                  input: (newValue, el) => {
                    let value = this.handleReplaceNumber(newValue);
                    el.changeModel(value);
                    row[prop] = value;
                  },
                },
                attrs: {
                  type: "number",
                },
              };
              return createInputVNode(this, row, prop, opts);
            } else {
              if (row.taskName === "预存(二)") {
                // console.log(JSON.parse(row.rulesJson),'JSON.parse(row.rulesJson)');
                let innerHTML = ""
                JSON.parse(row.rulesJson).forEach((item,index)=>{
                  innerHTML += `${item.intNum}<br>` 
                })
                // console.log(innerHTML,'innerHTML');
                return createHTMLVNode(
                  this,
                  innerHTML
                );
              }
              return createHTMLVNode(this, row[prop]);
            }
          },
        },
        {
          prop: "limitedNum",
          label: "上限",
          width: 250,
          formatter: (row, prop) => {
            if (row.rulesType !== 1 && row.edit) {
              const opts = {
                options: limitTypeOptions,
                inputDisplay: row.limitedType !== 1 ? "" : "none",
                spanDisplayOne: row.limitedType !== 1 ? "" : "none",
                spanDisplayTwo: row.limitedType !== 1 ? "" : "none",
                spanInnerTextOne: "上限",
                spanInnerTextTwo: "次",
                inputNumberValue: "limitedNum",
                inputNumberWidth: 60,
                methods: {
                  input: (newValue, el) => {
                    let value = this.handleReplaceNumber(newValue);
                    el.changeModel(value);
                    row[prop] = value;
                  },
                },
                attrs: {
                  type: "number",
                },
              };
              if (row.rulesJson) {
                // rulesJson 有值的情况下表示是特殊行（订单消费），单独处理上限单元格数据格式
                opts.spanInnerTextTwo =
                  consumMap[JSON.parse(row.rulesJson).type];
              }
              return createInputSpanVNode(this, row, "limitedType", opts);
            } else {
              if (row.rulesType === 2) {
                if (row.limitedType === 1) {
                  return "无上限";
                }
                if (row.rulesJson) {
                  return createHTMLVNode(
                    this,
                    `${limitTypeMap[row.limitedType]} 上限 ${row.limitedNum} ${
                      consumMap[JSON.parse(row.rulesJson).type]
                    }`
                  );
                }
                return createHTMLVNode(
                  this,
                  `${limitTypeMap[row.limitedType]} 上限 ${row.limitedNum} 次`
                );
              } else {
                if (row.taskName === "预存(二)") {
                //   // console.log(JSON.parse(row.rulesJson),'JSON.parse(row.rulesJson)');
                  let innerHTML = ""
                //   JSON.parse(row.rulesJson).forEach((item,index)=>{
                    if (row.limitedType === 1) {
                      innerHTML += "无上限<br>"
                    } else {
                      innerHTML += `${limitTypeMap[row.limitedType]}，上限 ${row.limitedNum} 次<br>`
                    }
                    
                //   })
                //   // console.log(innerHTML,'innerHTML');
                  return createHTMLVNode(
                    this,
                    innerHTML
                  );
                } else {
                  return "/";
                }
              }
            }
            
          },
        },
        {
          prop: "intime",
          label: "积分有效期",
          width: 240,
          formatter: (row, prop) => {
            if (row.edit) {
              const opts = {
                options: integraTimeOptions,
                inputDisplay: row.effectiveType === 2 ? "" : "none",
                spanDisplayTwo: row.effectiveType === 2 ? "" : "none",
                spanInnerTextTwo: "个月",
                inputNumberValue: "termNum",
                inputNumberWidth: 60,
                methods: {
                  input: (newValue, el) => {
                    let value = this.handleReplaceNumber(newValue);
                    el.changeModel(value);
                    row.termNum = value;
                  },
                },
                attrs: {
                  type: "number",
                },
              };
              return createInputSpanVNode(this, row, "effectiveType", opts);
            } else {
              // 永久
              if (row.effectiveType === 1) {
                return createHTMLVNode(this, integraTimeMap[row.effectiveType]);
              } else {
                // 固定日期
                return createHTMLVNode(
                  this,
                  `${integraTimeMap[row.effectiveType]} ${row.termNum} 个月`
                );
              }
            }
          },
        },
        {
          prop: "updateUser",
          label: "操作人",
          width: 80,
        },
        {
          prop: "updateTime",
          label: "更新时间",
        },
      ],
      tableData: [],
      mapArr: [],
      sysIntegralInfo: {},
    };
  },
  created() {},
  mounted() {
    console.log(this.$store.state.userInfo, "iiiiii");
    this.getSysIntegralInfo();
  },
  methods: {
    getSysIntegralInfo() {
      // 获取用户基础信息
      let params = {
        // this.$store.state.userInfo.regionId
        // regionId: 'eb1e1ad8-85af-42d0-ba3c-21229be19009'
        regionId: this.$store.state.userInfo.orgId
      };
      this.$axios.get(getListByPage, { params: params }).then((res) => {
        if (res.status === 100 && res.data) {
          // this.sysIntegralInfo = res.data;
          // this.form.integralName = res.data.integralName;
          // this.form.intNum = res.data.intNum || undefined;
          // this.form.unit = res.data.unit || undefined;
          const list = res.data.configVList;
          this.handleWashingData(res.data.configVList);
          list.forEach((item) => {
            item.edit = false;
          });
          this.tableData = list;
        }
      });
    },

    handleWashingData(data) {
      // mapArr 已存在的情况下不更新，解决表格数据修改后重复执行 span-method 所产生的bug
      if (!this.mapArr.length) {
        for (let i = 0; i < data.length; i++) {
          if (i === 0) {
            this.mapArr.push(1);
            this.pos = 0;
          } else {
            // 判断当前元素与上一个元素是否相同
            if (data[i].classify === data[i - 1].classify) {
              this.mapArr[this.pos] += 1;
              this.mapArr.push(0);
            } else {
              this.mapArr.push(1);
              this.pos = i;
            }
          }
        }
      }
    },

    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        const _row = this.mapArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },

    async handleSwitchChange(e, row) {
      let text;
      if (e === 1) {
        row.rulesOn = 0;
        text = "开启";
      } else {
        row.rulesOn = 1;
        text = "关闭";
      }
      const status = await this.$confirm(`确认${text}该积分任务?`, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        distinguishCancelAndClose: true,
        closeOnClickModal: false,
        type: "warning",
      });
      this.handleConfirmCallback(status, row);
    },
    // 弹窗按钮点击回调
    handleConfirmCallback(status, row) {
      const ruleStatus = {
        true: 1,
        false: 0,
      };
      if (status && row.rulesOn === 1) {
        row.rulesOn = ruleStatus[!status];
        // 开启回调
        this.handleChangeRules(row);
      } else if (status && row.rulesOn === 0) {
        row.rulesOn = ruleStatus[status];
        // 关闭回调
        this.handleChangeRules(row);
      }
    },

    edit(row) {
      // row.edit = true;
      this.$router.push({
        path: "systemIntegraRulesForm",
        query: {
          // userInfo: JSON.stringify(this.userInfo),
          id: row.id
        },
      });
    },
    // 规则开启关闭
    async handleChangeRules(row) {
      const { id, rulesOn } = row;
      const data = { id, rulesOn };
      await this.$axios.post(updateSysIntegralConfig, data);
      this.getSysIntegralInfo();
    },

    handleReplaceNumber(val) {
      return val.toString().replace(/\.|\-/g, "").slice(0, 8) - 0;
    }
  }
}
</script>

<style lang="scss" scoped>
.table-container {
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  overflow: scroll;
  .system-info {
    border: 1px solid rgba(242, 242, 242, 1);
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 30px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(251, 251, 251, 1);
    color: #666;
    font-size: 14px;
  }
  .subject-info {
    width: 100%;
    padding: 10px 20px;
    // margin-left: 20px;
    box-sizing: border-box;
    // background-color: rgba(251, 251, 251, 1);
    display: flex;
    justify-content: space-between;
    // border: 1px solid rgba(242, 242, 242, 1);
    border-radius: 4px;
    margin-bottom: 20px;
    .subject-left {
      display: flex;
      align-items: center;
      height: 100px;
      font-size: 20px;
      font-weight: bold;
      color: #666;
      justify-content: space-around;
      flex: 1;
      .username {
        flex: 1;
      }
    }
    .subject-right {
      > div {
        display: block;
        margin-top: 12px;
      }
    }
  }
  .basic-info {
    display: flex;
    justify-content: space-between;
    padding: 30px 20px 10px;
    .basic-left {
      flex: 1;
      justify-content: center;
      display: flex;
      .panel-wrap {
        width: 50%;
        .basic-row-text {
          padding: 0 10px;
          color: #606266;
        }
        .yuan-container {
          ::v-deep .el-form-item__content {
            margin-left: 0 !important;
          }
        }
        .botBtn {
          margin-right: 10px;
        }
      }
    }
  }
  .line-style {
    background-color: rgba(251, 251, 251, 1);
    padding: 10px 30px;
    text-align: left;
    position: relative;
    &::before {
      content: "";
      display: inline-block;
      width: 4px;
      height: 20px;
      position: absolute;
      left: 20px;
      top: 50%;
      margin-top: -10px;
      background: #409eff;
    }
  }
  .btn-box {
    ::v-deep .el-button {
      width: 100%;
      margin-top: 5px;
      display: block;
    }
  }
  ::v-deep .el-tooltip {
    padding-right: 10px;
  }
  ::v-deep .el-icon-question {
    position: absolute;
    top: 15px;
  }
  ::v-deep input::-webkit-outer-spin-button,
  ::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  ::v-deep input[type="number"] {
    -moz-appearance: textfield;
  }
}
</style>
